<template>
  <y_layout>

    <el-card shadow="hover" :body-style="{padding:'1rem 1rem'}" style="width: 100%;">
      <el-page-header @back="$router.back()" content="商家视频列表"></el-page-header>
      <div style="padding: 1rem;text-align: right">
        <el-button @click="dialogTableVisible=true" size="medium" type="primary" icon="el-icon-edit">新增</el-button>
      </div>
      <el-table v-loading="loading" :data="list" border style="width: 100%">
        <el-table-column prop="id" label="编号" ></el-table-column>
        <el-table-column prop="title" label="标题" ></el-table-column>
        <el-table-column label="缩略图">
          <template #default="s">
            <img v-if="s.row.poster" style="width: 3rem;height: 3rem;border-radius: .5rem" :src="s.row.poster | tomedia" alt="">
          </template>
        </el-table-column>
        <el-table-column label="视频">
          <template #default="s">
            <a :href="s.row.src | tomedia" target="_blank">点击预览</a>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" :formatter="(item)=>item.status ? '显示' : '隐藏'"></el-table-column>
        <el-table-column prop="goods_id" :formatter="(e)=>e.goods_id ? e.goods_id : ''" label="关联商品编号" ></el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <el-button @click="edit(scope.row)" type="primary" size="small">编辑</el-button>
            <el-button @click="del(scope.row.id)" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog title="编辑视频" destroy-on-close :visible.sync="dialogTableVisible" @close="resetSubmit">
      <div class="flex-def flex-zCenter">
        <el-form :model="form" label-width="5rem" style="width: 40rem">
          <el-form-item label="缩略图">
            <y_upload_img v-model="form.poster"></y_upload_img>
          </el-form-item>
          <el-form-item label="标题">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="视频">
            <y_upload_video v-model="form.src"></y_upload_video>
          </el-form-item>
          <el-form-item label="显示">
            <el-switch v-model="form.status"></el-switch>
          </el-form-item>
          <el-form-item label="关联商品">
            <y_merchant_goods_choose v-model="form.goods_id" :merchant_id="parseInt($route.query.merchant_id)"></y_merchant_goods_choose>
          </el-form-item>
          <el-form-item align="right">
            <el-button @click="submit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";
import y_upload_img from "@/components/y_upload_img";
import y_upload_video from "@/components/y_upload_video";
import y_merchant_goods_choose from "@/components/plugin/shop/y_merchant_goods_choose";
export default {
  name: "ivideo",
  components:{
    y_layout,
    y_upload_img,
    y_upload_video,
    y_merchant_goods_choose,
  },
  data(){
    return{
      dialogTableVisible:false,
      list:[],
      form:{
        title:"",
        poster:"",
        src:"",
        status:false,
        goods_id:0,
      },
      loading:false,
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    del(id){
      this.$api.ivideo.del({
        id,
        merchant_id: parseInt(this.$route.query.merchant_id)
      }).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    submit(){
      if(!this.form.id){
        this.form.merchant_id = parseInt(this.$route.query.merchant_id);
        this.$api.ivideo.add(this.form).then(()=>{
          this.$message.success("操作成功");
          this.dialogTableVisible = false;
          this.resetSubmit();
          this.load();
        })
        return;
      }
      this.$api.ivideo.update(this.form).then(()=>{
        this.$message.success("操作成功");
        this.dialogTableVisible = false;
        this.resetSubmit();
        this.load();
      })
    },
    load(){
      this.loading = true;
      this.$api.ivideo.all({
        merchant_id: parseInt(this.$route.query.merchant_id)
      }).then(res=>{
        this.list = res;
        this.loading = false;
      })
    },
    resetSubmit() {
      this.form = {
        poster:"",
        src:"",
        status:false,
      };
    },
    edit(e){
      this.form = {
        ...e
      };
      this.dialogTableVisible = true;
    }
  }
}
</script>

<style scoped>

</style>